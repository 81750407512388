const t = "footer", e = {
  Editor: "t-editor",
  Anchor: "t-editor__anchor",
  Bold: "t-editor__bold",
  Button: "t-editor__button",
  Callout: "t-editor__callout",
  Code: "t-editor__code",
  CodeMirror: "t-editor__codemirror",
  Detail: "t-editor__detail",
  Embed: "t-editor__embed",
  File: "t-editor__file",
  FinePrint: "t-editor__fine-print",
  Heading: "t-editor__heading",
  Highlight: "t-editor__highlight",
  Image: "t-editor__image",
  InlineCode: "t-editor__inline-code",
  Italic: "t-editor__italic",
  LineBreak: "t-editor__line-break",
  PageLink: "t-editor__page-link",
  List: "t-editor__list",
  CheckList: "t-editor__checklist",
  Mark: "t-editor__mark",
  Math: "t-editor__math",
  PageTitle: "t-editor__page-title",
  Paragraph: "t-editor__paragraph",
  Quote: "t-editor__quote",
  Strike: "t-editor__strike",
  Table: "t-editor__table",
  Underline: "t-editor__underline",
  Subscript: "t-editor__subscript",
  Superscript: "t-editor__superscript"
};
export {
  e as EditorClasses,
  t as customFooterClass
};
