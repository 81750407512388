<script setup lang="ts">
import {
  getHeaderLinks,
  type Guide,
  type Page,
  type Project,
  type Version,
} from '@scalar-org/entities/project'
import { customFooterClass, DocumentClasses } from '@scalar-org/guide'
import {
  DocumentHeader,
  DocumentMobileHeader,
} from '@scalar-org/library/composed'
import { useMediaQuery, useResizeObserver } from '@vueuse/core'
import { ref, type Ref } from 'vue'
import { useRouter } from 'vue-router'

import { resolveHeaderLink } from '@/helpers/resolveLink'
import { useSidebarState } from '@/hooks'
import GuideSidebarContent from '@/views/guides/GuideSidebarContent.vue'

defineProps<{
  project: Project
  activeVersion: Version
  guide: Guide | undefined
  versionNames: Record<string, string>
}>()

const router = useRouter()
// Track the container height to control the sidebar height
const elementHeight = ref(0)
const documentEl: Ref<HTMLElement | null> = ref(null)

useResizeObserver(documentEl, (entries) => {
  elementHeight.value = entries[0].contentRect.height
})

const isMobile = useMediaQuery('(max-width: 1000px)')

const { sidebarState, toggleSidebar, setSearch } = useSidebarState()

function navigate(uid: string) {
  router.push({ name: uid, replace: true })
}
</script>
<template>
  <main
    ref="documentEl"
    :class="{
      [DocumentClasses.Document]: true,
      'footer-below-sidebar': activeVersion.footerBelowSidebar,
      'hide-aside-right': true,
    }"
    :style="{ '--full-height': `${elementHeight}px` }">
    <!--  -->
    <!--  -->
    <!-- Header -->
    <div
      v-if="!isMobile"
      class="layout-header">
      <DocumentHeader
        :activeGuide="guide?.uid"
        :activeVersion="activeVersion.uid"
        :headerLinks="getHeaderLinks(activeVersion)"
        :project="project"
        :resolveInternalLinks="(item) => resolveHeaderLink(item, router)"
        :versions="versionNames"
        @navigate="(_, uid) => navigate(uid)"
        @selectVersion="navigate" />
    </div>
    <!--  -->
    <!--  -->
    <!-- Navigation Sidebar -->
    <aside
      class="layout-aside-left"
      :class="DocumentClasses.Sidebar">
      <DocumentMobileHeader
        v-if="isMobile"
        :label="'404 Not Found'"
        :showSideBar="sidebarState.showSidebar"
        @showSearch="() => setSearch(true)"
        @toggleSidebar="toggleSidebar" />
      <div
        v-else
        class="layout-aside-content">
        <GuideSidebarContent
          :activePage="<Page>{}"
          :isPaid="true"
          :sidebar="guide?.sidebar ?? null"></GuideSidebarContent>
      </div>
    </aside>
    <!--  -->
    <!--  -->
    <!-- Primary Guide Content -->
    <!-- NOTE: .editor class is REQUIRED for CSS reset to skip this block -->
    <div class="editor content-404">
      <h1>404</h1>
      <p>Page not found</p>
    </div>
    <!--  -->
    <!--  -->
    <!-- Footer - Disable for now as it feels awkward with so little content -->
    <div
      v-if="false"
      class="layout-footer">
      <div
        class="layout-footer"
        :class="customFooterClass"
        v-html="activeVersion.footer" />
    </div>
  </main>
</template>
<style scoped>
.content-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: var(--document-height);
}
</style>
