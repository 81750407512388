/** Some elements will require manual hydration after rendering */
import { type Ref, watch } from 'vue'
import type { Router } from 'vue-router'

// ---------------------------------------------------------------------------
// Header click handling

function handleHeaderLink(element: HTMLElement) {
  element.scrollIntoView(true)

  window.location.hash = `#${element.id}`
  navigator.clipboard.writeText(`${window.location.href}`)

  element.classList.add('show-copied')
  setTimeout(() => element?.classList.remove('show-copied'), 1200)
}

function hydrateHeadings() {
  const headings: NodeListOf<HTMLElement> = document.querySelectorAll(
    `[data-scalar-type="heading"]`,
  )

  headings.forEach((heading) => {
    // Add copy handler. Attribute check prevents adding multiple listeners
    const icon = heading.querySelector('.icon')
    if (icon && !icon.hasAttribute('hydration-has-listener')) {
      icon.addEventListener('click', () => handleHeaderLink(heading))
      icon.setAttribute('hydration-has-listener', 'true')
    }

    // Scroll to heading if it's the current hash
    if (window && window.location.hash === `#${heading.id}`)
      heading.scrollIntoView(true)
  })
}

// ---------------------------------------------------------------------------
// Page links need to be connected to the router logic

function hydratePageLinks(router: Router) {
  const links: NodeListOf<HTMLElement> = document.querySelectorAll(
    `[data-scalar-type="page-link"]`,
  )

  links.forEach((element) => {
    if (!element.hasAttribute('hydration-has-listener')) {
      const id = element.dataset.linkId
      element.addEventListener('click', () => {
        if (id) router.push({ name: id })
      })
      element.setAttribute('hydration-has-listener', 'true')
    }
  })
}

// ---------------------------------------------------------------------------

function hydrateImages(isDark: Ref<boolean>) {
  const toggleImages: HTMLImageElement[] = []

  watch(isDark, () => {
    if (isDark.value) {
      toggleImages.forEach((img) => {
        img.src = img.dataset.darkModeSrc || ''
      })
    } else {
      toggleImages.forEach((img) => {
        img.src = img.dataset.src || ''
      })
    }
  })

  const images: NodeListOf<HTMLImageElement> = document.querySelectorAll(
    `[data-scalar-type="image"]`,
  )
  images.forEach((element) => {
    const darkModeSrc = element.dataset.darkModeSrc

    if (darkModeSrc) {
      toggleImages.push(element)

      // If we are currently in dark mode then set on load
      if (isDark.value) element.src = darkModeSrc
    }
  })
}

// ---------------------------------------------------------------------------
// Hydrate Code block copy handling

function handleCodeBlockCopy(element: HTMLElement) {
  const content = element.parentElement?.querySelector('pre')?.innerText

  if (content) navigator.clipboard.writeText(content)

  element.classList.add('show-copied')
  setTimeout(() => element?.classList.remove('show-copied'), 1200)
}

function hydrateCodeBlocks() {
  const codeBlocks: NodeListOf<HTMLElement> = document.querySelectorAll(
    `[data-scalar-type="code-block-copy"]`,
  )

  codeBlocks.forEach((element) => {
    if (!element.hasAttribute('hydration-has-listener')) {
      element.addEventListener('click', () =>
        handleCodeBlockCopy(element as HTMLElement),
      )
      element.setAttribute('hydration-has-listener', 'true')
    }
  })
}

// ---------------------------------------------------------------------------

/** Run all client side hydration on app load */
export function runHydration(router: Router, isDark: Ref<boolean>) {
  hydrateHeadings()
  hydratePageLinks(router)
  hydrateImages(isDark)
  hydrateCodeBlocks()
}
