import { SidebarType } from './version/sidebar.js';

/** Gets the first version from a project */
function getFirst(entity, 
/** Project or version to extract entities from */
base) {
    // Get the active version or the first version if no active version is set
    const version = 'versions' in base
        ? (base.versions[base.activeVersionId] ??
            base.versions[Object.keys(base.versions)[0]])
        : base;
    if (!version)
        throw Error('No version found in project');
    if (entity === 'version')
        return version;
    const guide = version.guides[version.primaryGuideId] ??
        version.guides[Object.keys(version.guides)[0]];
    const reference = version.references[version.primaryReferenceId] ??
        version.references[Object.keys(version.references)[0]];
    if (entity === 'guide') {
        return guide || null;
    }
    if (entity === 'reference') {
        return reference || null;
    }
    const items = Object.values(guide.sidebar.items);
    const firstPage = items.find((item) => item.type === SidebarType.Page);
    if (entity === 'page') {
        return firstPage || null;
    }
    throw Error('Invalid entity type specified');
}

export { getFirst };
