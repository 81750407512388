import { readonly as o, reactive as s } from "vue";
const i = () => ({
  pageTitle: "Getting started",
  pageDescription: "",
  pageImage: "",
  sidebarItems: void 0
}), t = s(i());
function r(e, a) {
  t[e] = a;
}
const c = () => ({
  state: o(t),
  setItem: r
});
export {
  c as useEditorStore
};
