import { slugify } from '../url/slugify.js';
import { getPathVariables, normalizePath } from '../url/path.js';

/**
 * Take a sidebar list and recursively generate slugified paths */
function recursivePathMap(itemList, itemsAtLevel, parentPath, pathMap = {}) {
    const nameCount = {};
    // For each item at a given level we check for duplicate names
    itemsAtLevel.forEach((uid) => {
        const item = itemList[uid];
        // ensure each item exists in the item list
        if (item) {
            const name = parentPath
                ? `${parentPath}/${slugify(item.title)}`
                : slugify(item.title);
            // Either assign the new nested path OR assign with a suffixed number
            pathMap[uid] = nameCount[name] ? `${name}-${nameCount[name]}` : name;
            nameCount[name] = nameCount[name] ? nameCount[name] + 1 : 1;
            // If we have nested children then append
            if (item.children) {
                recursivePathMap(itemList, item.children, pathMap[uid], pathMap);
            }
        }
    });
    return pathMap;
}
/**
 * Recursively search a list of items who have possible children for
 * the first item that matches the pageKey (ex: 'Page')
 */
function findFirstPage(items, children, 
/** Key to assert the type of the item to search for */
pageKey = 'Page') {
    for (const uid of children) {
        const item = items[uid];
        if (item?.type === pageKey)
            return item.uid;
        if (item?.children) {
            const firstPage = findFirstPage(items, item.children, pageKey);
            if (firstPage)
                return firstPage;
        }
    }
    return undefined;
}
/** Check the order of keys in a template matches the required order */
function templateOrderMatches(template, requiredOrder) {
    // Get the keys that are found in the path template
    const pathKeys = getPathVariables(template);
    // If there are keys in the order that are not present we ignore them
    const strippedOrder = requiredOrder.filter((key) => pathKeys.includes(key));
    // If there are keys in the path that are not in the order we ignore them
    const strippedPathKeys = pathKeys.filter((key) => strippedOrder.includes(key));
    return strippedPathKeys.every((key, idx) => key === strippedOrder[idx]);
}
/**
 * Validates that a given path template is valid
 * Returns a sanitized path and a list of possible issues
 *
 * NOTE: Removes all trailing and leading slashes. Vue router usually requires these
 *       for explicit paths from root
 */
function validateDocsPathTemplate({ base, type, multipleVersions, multipleLocales, }) {
    const issues = [];
    const sanitized = normalizePath(base.replaceAll(' ', ''));
    const keys = getPathVariables(sanitized);
    if (keys.includes('version') &&
        keys.includes(type) &&
        keys.indexOf('version') >= keys.indexOf(type)) {
        issues.push(`The {{version}} key must be before the {{${type}}} key in your path template.`);
    }
    if (multipleVersions && !keys.includes('version'))
        issues.push('With multiple versions the {{version}} key must be included.');
    if (multipleLocales && !keys.includes('locale'))
        issues.push('If you have more than one locale the {{locale}} key must be a part of your path template.');
    if (type !== 'flat-guide' && !keys.includes(type)) {
        issues.push(type === 'guide'
            ? 'The {{guide}} key must be present if there are multiple guides in any version.'
            : `The {{${type}}} key must be present in the path template.`);
    }
    return {
        path: sanitized,
        withoutGuide: normalizePath(sanitized.replace('{{guide}}', '')),
        withoutVersion: normalizePath(sanitized.replace('{{version}}', '')),
        withoutLocale: normalizePath(sanitized.replace('{{locale}}', '')),
        withoutVersionAndLocale: normalizePath(sanitized.replace('{{version}}', '').replace('{{locale}}', '')),
        issues,
        error: issues.length > 0,
    };
}
/**
 * Get the default path templates for deployed sites
 *
 * When we have only one version we drop the version slug by default
 * Guide and refernce slugs are shown by default
 */
function getFallbackTemplates(numVersions) {
    const flattenVersion = numVersions === 1;
    return {
        reference: flattenVersion ? '{{reference}}' : '{{version}}/{{reference}}',
        guide: flattenVersion ? '{{guide}}' : '{{version}}/{{guide}}',
    };
}

export { findFirstPage, getFallbackTemplates, recursivePathMap, templateOrderMatches, validateDocsPathTemplate };
