import { nanoid } from 'nanoid';
import { z } from 'zod';
import { defaultFooterCSS, defaultFooter } from '../footer.js';
import { guideSchema, defaultGuide } from './guide.js';
import { referenceSchema, defaultReference } from './reference.js';
import { headerLinkSchema, getHeaderLinks } from '../header-links.js';
import { nanoidSchema } from '../../utility/index.js';
import { SidebarType } from './sidebar.js';

const versionSchema = z
    .object({
    uid: nanoidSchema,
    name: z.string().default('Default Version'),
    primaryGuideId: z.string(),
    primaryReferenceId: z.string(),
    references: z.record(z.string(), referenceSchema),
    guides: z.record(z.string(), guideSchema),
    header: headerLinkSchema.array(),
    footer: z.string().default(''),
    footerCss: z.string().default(''),
    footerBelowSidebar: z.boolean().default(false),
    headScript: z.string().default(''),
    bodyScript: z.string().default(''),
})
    .transform((v) => {
    return {
        ...v,
        header: getHeaderLinks({
            guides: v.guides,
            references: v.references,
            header: v.header,
        }),
    };
});
function defaultVersion(initialState = {}, options = {}) {
    const guide = defaultGuide();
    const reference = defaultReference();
    if (options.hideGuide)
        guide.show = false;
    if (options.hideReference)
        reference.show = false;
    return versionSchema.parse({
        uid: nanoid(),
        name: 'Version 0',
        primaryGuideId: guide.uid,
        primaryReferenceId: reference.uid,
        references: { [reference.uid]: reference },
        guides: { [guide.uid]: guide },
        header: getHeaderLinks({
            guides: { [guide.uid]: guide },
            references: { [reference.uid]: reference },
            header: [],
        }),
        footer: defaultFooter,
        footerCss: defaultFooterCSS,
        footerBelowSidebar: false,
        headScript: '',
        bodyScript: '',
        ...initialState,
    });
}
/** Extracts all yjs ids from a version */
function getAllYjsIds(version) {
    const documentByUid = {};
    const referenceByUid = {};
    Object.values(version.references).forEach((reference) => {
        referenceByUid[reference.uid] = reference.yjsReference;
    });
    Object.values(version.guides).forEach((guide) => {
        Object.values(guide.sidebar.items).forEach((item) => {
            if (item.type === SidebarType.Page) {
                documentByUid[item.uid] = item.yjsReference;
            }
        });
    });
    return {
        /** Map of guide page uid to yjs document id */
        documentByUid: documentByUid,
        /** Map of reference uid to yjs reference id */
        referenceByUid: referenceByUid,
    };
}

export { defaultVersion, getAllYjsIds, versionSchema };
