import { defineComponent as b, ref as d, watch as c, createElementBlock as i, openBlock as n, createCommentVNode as s, createElementVNode as r, toDisplayString as m } from "vue";
import { findPreviousPage as f, findNextPage as k } from "../helpers/pageNavigation.js";
const N = { class: "t-editor__page-nav" }, y = {
  key: 0,
  class: "t-editor__page-nav__container"
}, T = /* @__PURE__ */ b({
  __name: "PageNav",
  props: {
    activePage: {},
    sidebar: {}
  },
  emits: ["goToNextPage", "goToPrevPage"],
  setup(p, { emit: _ }) {
    const t = p, u = _, a = d(null), o = d(null);
    return c(
      () => t.activePage,
      () => {
        a.value = f(t.sidebar, t.activePage), o.value = k(t.sidebar, t.activePage);
      },
      {
        immediate: !0
      }
    ), (l, e) => {
      var v, g;
      return n(), i("div", N, [
        l.sidebar && (a.value || o.value) ? (n(), i("div", y, [
          a.value ? (n(), i("button", {
            key: 0,
            class: "t-editor__page-nav__item t-editor__page-nav__item-left",
            type: "button",
            onClick: e[0] || (e[0] = (P) => u("goToPrevPage", a.value))
          }, [
            e[2] || (e[2] = r("b", null, "Previous Page", -1)),
            r("p", null, m((v = l.sidebar.items[a.value]) == null ? void 0 : v.title), 1)
          ])) : s("", !0),
          o.value ? (n(), i("button", {
            key: 1,
            class: "t-editor__page-nav__item t-editor__page-nav__item-right",
            type: "button",
            onClick: e[1] || (e[1] = (P) => u("goToNextPage", o.value))
          }, [
            e[3] || (e[3] = r("b", null, "Next Page", -1)),
            r("p", null, m((g = l.sidebar.items[o.value]) == null ? void 0 : g.title), 1)
          ])) : s("", !0)
        ])) : s("", !0)
      ]);
    };
  }
});
export {
  T as default
};
