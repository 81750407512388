import { z } from 'zod';
import { timestampSchema } from '../utility/index.js';

const websiteSchema = z.object({
    lastDeployed: timestampSchema,
    subdomainPrefix: z.string().transform((val) => val.toLowerCase()),
    customUrl: z.string().transform((val) => val.toLowerCase()),
    subPath: z.string().optional(),
    title: z.string().optional(),
    description: z.string().optional(),
    favicon: z.string().optional(),
    ogImage: z.string().optional(),
    isPrivate: z.boolean().default(false),
    allowedUsers: z.string().array().optional().default([]),
    allowedDomains: z.string().array().optional().default([]),
    proxy: z.string().url().optional(),
    routing: z
        .object({
        /**
         * Path replacement pattern for guides.
         * Defaults to `{{version}}/{{guide}}` with version dropped when there is only a single version.
         */
        guidePathPattern: z.string().optional(),
        /**
         * Path replacement pattern for references.
         * Defaults to `{{version}}/{{reference}}` with version dropped when there is only a single version.
         */
        referencePathPattern: z.string().optional(),
    })
        .default({
        guidePathPattern: '',
        referencePathPattern: '',
    }),
    redirects: z
        .array(z.object({
        from: z.string(),
        to: z.string(),
    }))
        .default([]),
});
function defaultWebsite(subdomainPrefix) {
    return websiteSchema.parse({
        lastDeployed: 0,
        subdomainPrefix: subdomainPrefix ?? '',
        customUrl: '',
        title: '',
        description: '',
        favicon: '',
        ogImage: '',
        allowedUsers: [],
        allowedDomains: [],
        isPrivate: false,
    });
}

export { defaultWebsite, websiteSchema };
