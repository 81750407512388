import { nanoid } from 'nanoid';
import { z } from 'zod';
import { nanoidSchema } from '../utility/index.js';

/** See @scalar-org/helpers/url/slugify.ts */
const slugify = (v) => v
    .normalize('NFC')
    .toLowerCase()
    .trim()
    .replace(/[^\p{L}\p{M}\p{N}\s-]/gu, '')
    .replace(/[\s-]+/g, '-')
    .replace(/^-+|-+$/g, '')
    .slice(0, 255);
const themeSchema = z.preprocess((arg) => {
    if (!arg)
        return arg;
    if (typeof arg === 'object')
        arg.identifier = slugify(arg.name);
    return arg;
}, z.object({
    uid: nanoidSchema,
    name: z.string().transform(slugify),
    description: z.string(),
    theme: z.string(),
    identifier: z.string().transform(slugify),
}));
const defaultTheme = (cssString = '') => ({
    uid: nanoid(),
    name: 'Default Theme',
    identifier: 'default',
    description: '',
    theme: cssString,
});

export { defaultTheme, themeSchema };
