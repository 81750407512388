import { z } from 'zod';
import { timestampSchema, nanoidSchema } from '../utility/index.js';

const publishRecordSchema = z.object({
    uid: nanoidSchema,
    projectUid: nanoidSchema,
    createdAt: timestampSchema,
    updatedAt: timestampSchema,
    status: z.union([
        z.literal('pending'),
        z.literal('building'),
        z.literal('deployed'),
        z.literal('inactive'),
        z.literal('error'),
        z.literal('deleted'),
    ]),
    message: z.string().default(''),
    domain: z.string().default(''),
});

export { publishRecordSchema };
