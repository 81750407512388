<script setup lang="ts">
import {
  SidebarType,
  type Page,
  type SidebarItem,
} from '@scalar-org/entities/project'
import { SidebarElement, SidebarGroup } from '@scalar-org/library/composed'
import { useMediaQuery } from '@vueuse/core'
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import { useSidebarState } from '@/hooks'

const props = defineProps<{
  level: number
  children?: string[]
  items: Partial<Record<string, SidebarItem>>
  activePage: Page
}>()
const emit = defineEmits<{
  (e: 'bubbleUpOpen'): void
}>()
const isMobile = useMediaQuery('(max-width: 1000px)')
const childItems = computed(
  () => props.children?.map((c) => props.items[c]) ?? [],
)

const router = useRouter()

// If a page is selected we navigate
function handleSelectItem(uid: string) {
  const item = props.items[uid]
  if (item?.type === SidebarType.Page) {
    if (isMobile.value) setSidebar(false)

    console.log('PUSHING: ', uid)
    router.push({ name: uid })
  }
}

const {
  sidebarState,
  setSidebar,
  toggleCollapsedSidebarItem,
  setCollapsedSidebarItem,
} = useSidebarState()

/** Toggle the collapse state of nested tree items */
const handleToggleOpen = async (uid: string) => {
  toggleCollapsedSidebarItem(uid)
}

function isOpen(uid?: string) {
  if (!uid) return true
  return sidebarState.collapsedSidebarItems[uid]
}

function handleBubbleUp(uid: string) {
  setCollapsedSidebarItem(uid, true)
  emit('bubbleUpOpen')
}

childItems.value.forEach((child) => {
  if (child?.uid === props.activePage.uid) {
    emit('bubbleUpOpen')
  }
})

// on page load, if the level is root we default open the folders
// for a better UX
onMounted(() => {
  if (props.level === 0) {
    childItems.value.forEach((child) => {
      if (child?.type === SidebarType.Folder) {
        setCollapsedSidebarItem(child?.uid, true)
      }
    })
  }
})
</script>
<template>
  <SidebarGroup
    v-for="child in childItems"
    :key="child?.uid"
    :level="level">
    <SidebarElement
      v-if="child"
      :hasChildren="child?.children && child.children.length > 0"
      :isActive="activePage?.uid === child?.uid"
      :item="child"
      :open="isOpen(child?.uid)"
      :resolvePage="(uid) => router.resolve({ name: uid }).href"
      @select="handleSelectItem(child?.uid)"
      @toggleOpen="handleToggleOpen(child?.uid)">
      <GuideSidebarGroup
        v-if="child.children && child.children?.length > 0"
        :activePage="activePage"
        :children="child.children"
        :items="items"
        :level="level + 1"
        @bubbleUpOpen="handleBubbleUp(child?.uid)" />
    </SidebarElement>
  </SidebarGroup>
</template>
