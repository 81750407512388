<script setup lang="ts">
import type { Page, Sidebar } from '@scalar-org/entities/project'
import {
  DarkModeToggle,
  FindAnythingButton,
} from '@scalar-org/library/composed'
import { useMediaQuery } from '@vueuse/core'

import ScalarFreePromo from '@/components/ScalarFreePromo.vue'
import { useSidebarState } from '@/hooks'

import GuideSidebarGroup from './GuideSidebarGroup.vue'

defineProps<{
  sidebar: Sidebar | null
  activePage: Page
  isPaid?: boolean
}>()
const isMobile = useMediaQuery('(max-width: 1000px)')

const { setSearch } = useSidebarState()
</script>
<template>
  <nav class="sidebar">
    <FindAnythingButton
      v-if="!isMobile"
      @click="setSearch(true)" />
    <div class="pages custom-scroll custom-scroll-self-contain-overflow">
      <GuideSidebarGroup
        v-if="sidebar"
        :activePage="activePage"
        :children="sidebar.children"
        :items="sidebar.items"
        :level="0" />
    </div>
    <DarkModeToggle>
      <ScalarFreePromo v-if="!isPaid" />
    </DarkModeToggle>
  </nav>
</template>
