<script setup lang="ts"></script>
<template>
  <a
    class="scalar-premium-promo"
    href="https://www.scalar.com"
    target="_blank">
    Powered by scalar.com
  </a>
</template>
<style scoped>
.scalar-premium-promo {
  display: flex;
  align-items: center;
  font-size: var(--scalar-mini);
  text-decoration: none;
  color: var(--scalar-sidebar-color-2, var(--scalar-color-2));

  margin-bottom: -6px;
}
.scalar-premium-promo:hover {
  text-decoration: underline;
}
</style>
