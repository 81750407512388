import { z } from 'zod';
import { themeSchema } from '../theme/index.js';
import { nanoidSchema } from '../utility/index.js';
import { versionSchema } from './version/version.js';

const signupDataSchema = z.object({
    uid: nanoidSchema,
    version: versionSchema,
    theme: themeSchema.optional(),
    yjsDocs: z.record(z.string(), z.string()),
    yjsReferences: z.record(z.string(), z.string()),
});

export { signupDataSchema };
