import { reactive, readonly } from 'vue'

const sidebarState = reactive<{
  showSidebar: boolean
  showSearch: boolean
  collapsedSidebarItems: Partial<Record<string, boolean>>
}>({
  showSearch: false,
  showSidebar: true,
  collapsedSidebarItems: {},
})

/** Share sidebar state  */
export function useSidebarState() {
  return {
    sidebarState: readonly(sidebarState),
    toggleSearch: () => {
      sidebarState.showSearch = !sidebarState.showSearch
    },
    toggleSidebar: () => {
      sidebarState.showSidebar = !sidebarState.showSidebar
    },
    setSidebar: (value: boolean) => {
      sidebarState.showSidebar = value
    },
    setSearch: (value: boolean) => {
      sidebarState.showSearch = value
    },
    toggleCollapsedSidebarItem: (key: string) => {
      sidebarState.collapsedSidebarItems[key] =
        !sidebarState.collapsedSidebarItems[key]
    },
    setCollapsedSidebarItem: (key: string, value: boolean) => {
      sidebarState.collapsedSidebarItems[key] = value
    },
  }
}
