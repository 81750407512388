import { nanoid } from 'nanoid';
import { z } from 'zod';
import { timestampSchema, nanoidSchema, unixTimestamp } from '../utility/index.js';
import { websiteSchema, defaultWebsite } from './website.js';
import { activeDeploymentSchema } from './active-deployment.js';
import { versionSchema, defaultVersion } from './version/version.js';

// ---------------------------------------------------------------------------
const logoSchema = z.object({
    darkMode: z.string(),
    lightMode: z.string(),
});
// ---------------------------------------------------------------------------
const projectSchema = z
    .object({
    /** Unique identifier for the project */
    uid: nanoidSchema,
    /** Name of the project */
    name: z.string(),
    /** Versions of the project */
    versions: z.record(z.string(), versionSchema),
    /** Active template id for the project */
    activeTemplateId: z.string().optional().default(''),
    /** Active theme id for the project */
    activeThemeId: z.string().optional().default(''),
    /** Active version id for the project */
    activeVersionId: z.string().optional().default(''),
    /** Timestamp when the project was created */
    createdAt: timestampSchema,
    /** Timestamp when the project was last updated */
    updatedAt: timestampSchema,
    /** Logo for the project */
    logo: logoSchema,
    /** Deployed site configuration for a project */
    website: websiteSchema.describe('Deployed site configuration for a project'),
    /** Typesense id for the project */
    typesenseId: z.number().optional(),
    /** Record of the last successful deployment */
    activeDeployment: activeDeploymentSchema.nullable().optional(),
    /** Uid of the most recent publish record (failed or successful) */
    lastPublishedUid: z.string().nullable().optional(),
})
    .transform((project) => {
    if (Object.keys(project.versions).length === 0) {
        const v = defaultVersion({});
        project.versions[v.uid] = v;
    }
    if (!project.activeVersionId) {
        project.activeVersionId = Object.keys(project.versions)[0];
    }
    return project;
});
function defaultProject(initialState = {}) {
    const v = defaultVersion({});
    return projectSchema.parse({
        uid: nanoid(),
        name: '',
        activeTemplateId: '',
        activeThemeId: '',
        activeVersionId: v.uid,
        createdAt: unixTimestamp(),
        updatedAt: unixTimestamp(),
        header: [],
        logo: {
            darkMode: '',
            lightMode: '',
        },
        versions: {
            [v.uid]: v,
        },
        website: defaultWebsite(),
        activeDeployment: null,
        lastPublishedUid: null,
        ...initialState,
    });
}
// ---------------------------------------------------------------------------
const githubProjectSchema = z.object({
    uid: nanoidSchema,
    name: z.string(),
    createdAt: timestampSchema,
    /** Last publish status info */
    publishStatus: z.string().default(''),
    /** Last publish output message */
    publishMessage: z.string().default(''),
    /** Is the site private? */
    isPrivate: z.boolean().default(false),
    /** List of allowed users to access the site */
    allowedUsers: z.string().array().default([]),
    /** List of allowed email domains to access the site */
    allowedDomains: z.string().array().default([]),
    /** Reference info for the link repository */
    repository: z.object({
        linkedBy: z.string(),
        id: z.number(),
        name: z.string().min(2),
        configPath: z.string().default(''),
        branch: z.string().default(''),
        publishOnMerge: z.boolean().default(false),
    }),
    activeDeployment: activeDeploymentSchema.nullable().optional(),
    lastPublishedUid: z.string().nullable().optional(),
    typesenseId: z.number().optional(),
});
/** Check if a project is a GithubProject */
function isGithubProject(project) {
    return 'repository' in project;
}

export { defaultProject, githubProjectSchema, isGithubProject, logoSchema, projectSchema };
