<script setup lang="ts">
import { type Project } from '@scalar-org/entities/project'
import { Search } from '@scalar-org/library/composed'
import { useColorMode } from '@scalar/use-hooks/useColorMode'
import { useHead, useSeoMeta } from '@unhead/vue'
import { useMediaQuery } from '@vueuse/core'
import { inject, onMounted, watchEffect } from 'vue'
import { RouterView } from 'vue-router'

import { useSidebarState } from '@/hooks'

useColorMode()
const { sidebarState, setSidebar, setSearch } = useSidebarState()

// Inject the themed styles
const theme = inject<string>('theme')
const typesenseKey = inject<string>('typesenseKey')
const typesenseHost = inject<string>('typesenseHost')
const projectId = inject<string>('projectUid')
const project = inject<Project>('project')

useSeoMeta({
  title: project?.website.title || 'Scalar API Docs',
  description:
    project?.website.description ||
    'Beautiful API Documentation, built with Scalar',
  ogImage:
    project?.website.ogImage || 'https://docs.scalar.com/swagger-editor-og.png',
})
useHead({
  link: [
    {
      rel: 'icon',
      href: project?.website.favicon || '/fallback.favicon.png',
    },
  ],
  style: [
    {
      tagPosition: 'bodyOpen',
      textContent: theme,
    },
  ],
})

// Slight delay to allow all CSS to load and prevent repaint flicker
onMounted(() => {
  setTimeout(() => {
    document.body.style.visibility = 'visible'
  }, 200)
})

// Toggle the sidebar when we cross the responsive threshold
const isMobile = useMediaQuery('(max-width: 1000px)')
watchEffect(() => {
  setSidebar(!isMobile.value)
})
</script>
<template>
  <RouterView />
  <client-only>
    <Search
      v-if="typesenseKey && projectId"
      :projectId="projectId"
      :showSearch="sidebarState.showSearch"
      :typesenseHost="typesenseHost ?? ''"
      :typesenseKey="typesenseKey"
      @close="setSearch(false)" />
  </client-only>
</template>
<style>
#app {
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

body {
  visibility: hidden;
}
</style>
