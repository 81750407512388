import { defineComponent as B, computed as g, ref as h, reactive as _, watch as m, onMounted as j, onBeforeUnmount as S, createElementBlock as a, openBlock as u, Fragment as w, createElementVNode as d, createCommentVNode as T, withModifiers as y, toDisplayString as C, renderList as $, normalizeClass as x } from "vue";
import { EditorClasses as P } from "../styles.js";
const q = {
  key: 0,
  class: "level-1"
}, H = ["id"], M = ["href", "onClick"], A = /* @__PURE__ */ B({
  __name: "TableOfContents",
  props: {
    headings: {},
    pageTitle: {}
  },
  setup(k) {
    const v = k, f = g(
      () => Object.values(v.headings || {}).filter((e) => e.level === 1).map((e) => e.slug)
    ), s = h(""), r = h(""), o = _({}), i = g(
      () => Object.values(o).sort((e, t) => e.order - t.order)
    );
    function E() {
      Object.keys(o).forEach((e) => {
        f.value.includes(e) || delete o[e];
      }), f.value.forEach((e, t) => {
        var n;
        o[e] = {
          id: e,
          inView: ((n = o[e]) == null ? void 0 : n.inView) ?? !1,
          order: t
        };
      });
    }
    m(f, () => E(), { immediate: !0, deep: !0 });
    const O = (e) => {
      e.forEach((t) => {
        if (o[t.target.id] && (o[t.target.id].inView = t.isIntersecting), r.value = t.target.id, !t.isIntersecting && t.target.getBoundingClientRect().top >= window.innerHeight * 0.2 && Object.values(o).every((c) => !c.inView)) {
          const c = i.value.findIndex(
            (I) => I.id === t.target.id
          );
          c >= 1 && (r.value = i.value[c - 1].id);
        }
      });
    };
    m(
      [r, i],
      () => {
        const e = i.value.find((t) => t.inView);
        if (e) {
          s.value = e.id;
          return;
        }
        if (r.value) {
          s.value = r.value;
          return;
        }
      },
      { immediate: !0, deep: !0 }
    );
    let l;
    function p() {
      l && l.disconnect(), document.querySelectorAll(
        '[data-scalar-type="heading"][data-level="1"]'
      ).forEach((t) => l == null ? void 0 : l.observe(t));
    }
    j(() => {
      l = new IntersectionObserver(O, {
        // threshold: 1,
        // rootMargin: '-20%',
      }), p();
    }), S(() => l == null ? void 0 : l.disconnect()), m(() => Object.keys(v.headings || {}), p);
    function V(e) {
      s.value = e.parent || e.slug, window.location.replace(`#${e.slug}`);
      const t = document.getElementById(e.slug);
      t == null || t.scrollIntoView();
    }
    function b() {
      const e = document.querySelector(`.${P.PageTitle} h1`);
      e == null || e.scrollIntoView();
    }
    return (e, t) => (u(), a(w, null, [
      t[0] || (t[0] = d("h4", null, "On This Page", -1)),
      d("ul", null, [
        Object.keys(e.headings || {}).length === 0 ? (u(), a("li", q, [
          d("a", {
            class: "anchor",
            href: "#",
            onClick: y(b, ["prevent"])
          }, C(e.pageTitle), 1)
        ])) : T("", !0),
        (u(!0), a(w, null, $(e.headings, (n) => (u(), a("li", {
          id: n.uid,
          key: n.uid,
          class: x([
            `level-${n == null ? void 0 : n.level}`,
            { active: s.value === n.slug }
          ])
        }, [
          d("a", {
            class: "anchor",
            href: `#${n.slug}`,
            onClick: y(() => V(n), ["prevent"])
          }, C(n.name), 9, M)
        ], 10, H))), 128))
      ])
    ], 64));
  }
});
export {
  A as default
};
