/** Convert a text string to a readable, deterministic URL safe string */
const slugify = (str) => str
    // Consistent unicode composition
    .normalize('NFC')
    // Lower case
    .toLowerCase()
    // Trim spaces
    .trim()
    // Retain letters, numbers, spaces, hyphens and marks
    .replace(/[^\p{L}\p{M}\p{N}\s-]/gu, '')
    // Convert spaces and multi dashes to single dash
    .replace(/[\s-]+/g, '-')
    // Remove leading and trailing dashes
    .replace(/^-+|-+$/g, '')
    .slice(0, 255);
/** For nested slugs we join with a common separator */
const slugifyJoin = (child, parent) => {
    return [parent, child].join('__');
};
/** Map or uids to a specific slugified name with duplicate handling */
function uidUniqueSlugs(records, nameKey) {
    const names = {};
    const nameCount = {};
    records.forEach((record) => {
        const name = slugify(record[nameKey]);
        names[record.uid] = nameCount[name] ? `${name}-${nameCount[name]}` : name;
        nameCount[name] = nameCount[name] ? nameCount[name] + 1 : 1;
    });
    return names;
}

export { slugify, slugifyJoin, uidUniqueSlugs };
