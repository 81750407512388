/**
 * Clean up URLs paths
 *
 * 1. Remove duplicate slashes from a path *
 * 2. Removes leading and trailing slashes by default
 * 3. Option to force leading and trailing slashes if specified
 */
function normalizePath(path, options = {}) {
    const { addLeadingSlash = false, addTrailingSlash = false } = options;
    let base = path
        .split('/')
        .filter((e) => !!e)
        .join('/');
    if (addLeadingSlash)
        base = `/${base}`;
    if (addTrailingSlash)
        base = `${base}/`;
    return base;
}
/**
 * Gets a list of variables in a path template
 * All spaces and double slashes are ignored from the path
 *
 * @example
 * getPathVariables('/foo/{{version}}/{{guide}}') === ['version', 'guide']
 */
function getPathVariables(pathTemplate) {
    const path = normalizePath(pathTemplate.replaceAll(' ', ''));
    const varMatches = path.match(/{{([a-zA-Z0-9-_ ]*?)}}/g);
    return (varMatches?.map((varMatch) => varMatch.replace('{{', '').replace('}}', '')) ?? []);
}
/**
 * Substitutes variables in a path template with the provided substitutions
 * All spaces and double slashes are removed from the path
 * @example
 * substitutePathVariables('/foo/{{version}}/{{guide}}', {
 *   version: 'v0',
 *   guide: 'getting-started',
 * }) === '/foo/v0/getting-started'
 */
function substitutePathVariables(pathTemplate, substitutions) {
    // Spaces are invalid characters
    // Double slashes are invalid characters
    let path = normalizePath(pathTemplate.replaceAll(' ', ''));
    const pathVariables = getPathVariables(pathTemplate);
    pathVariables.forEach((key) => {
        if (substitutions[key]) {
            path = path.replace(`{{${key}}}`, substitutions[key]);
        }
    });
    return path;
}

export { getPathVariables, normalizePath, substitutePathVariables };
