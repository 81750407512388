import { z } from 'zod';

const sidebarPageSchema = z.object({
    name: z.string().optional().default(''),
    description: z.string().optional().default(''),
    backgroundImage: z.string().optional(),
    icon: z.string().optional(),
    path: z.string(),
    type: z.literal('page'),
    children: z.lazy(() => sidebarItemSchema.array()).optional(),
});
const sidebarFolderSchema = z.object({
    name: z.string(),
    icon: z.string().optional(),
    type: z.literal('folder'),
    children: z.lazy(() => sidebarItemSchema.array()),
});
const sidebarLinkSchema = z.object({
    name: z.string(),
    icon: z.string().optional(),
    type: z.literal('link'),
    url: z.string(),
});
const sidebarItemSchema = z.union([
    sidebarLinkSchema,
    sidebarPageSchema,
    sidebarFolderSchema,
]);
const guideSchema = z.union([
    z.object({
        name: z.string(),
        folder: z.string(),
        include: z.string().array().optional(),
        exclude: z.string().array().optional(),
        description: z.string().default(''),
    }),
    z.object({
        name: z.string(),
        sidebar: sidebarItemSchema.array(),
        description: z.string().default(''),
    }),
]);
const referenceSchema = z.object({
    name: z.string(),
    path: z.string(),
    description: z.string().default(''),
});
const optionalUrl = z.string().url().optional();
const scalarGitSpecSchema = z.object({
    $schema: z
        .string()
        .url()
        .default('https://cdn.scalar.com/schema/scalar-config.json'),
    publishOnMerge: z.boolean().optional(),
    subdomain: z.preprocess((arg) => {
        // Only take the subdomain if the full domain is provided
        return typeof arg === 'string' ? arg.split('.')[0] : arg;
    }, z.string().min(3).max(30)),
    customDomain: z.string().min(3).optional(),
    siteMeta: z
        .object({
        favicon: optionalUrl,
        ogImage: optionalUrl,
        title: z.string().optional(),
        description: z.string().optional(),
    })
        .default({}),
    siteConfig: z
        .object({
        theme: z.string().optional(),
        logo: z
            .union([
            optionalUrl,
            z.object({ darkMode: optionalUrl, lightMode: optionalUrl }),
        ])
            .optional(),
        footer: z.string().optional(),
        footerCss: z.string().optional(),
        footerBelowSidebar: z.boolean().optional(),
        headScript: z.string().optional(),
        bodyScript: z.string().optional(),
        cssFiles: z.string().array().optional(),
        cssString: z.string().optional(),
        routing: z
            .object({
            guidePathPattern: z.string().optional(),
            referencePathPattern: z.string().optional(),
        })
            .optional(),
    })
        .default({}),
    guides: guideSchema.array(),
    references: referenceSchema.array(),
});

export { scalarGitSpecSchema };
