import { z } from 'zod';
import { nanoidSchema } from '../../utility/index.js';
import { nanoid } from 'nanoid';

const referenceSchema = z.object({
    /** Unique identifier for the reference */
    uid: nanoidSchema,
    /** Title of the reference */
    title: z.string().transform((val) => (val === '' ? 'Reference' : val)),
    /** Description of the reference */
    description: z.string(),
    /** Yjs reference id for the reference */
    yjsReference: nanoidSchema,
    /** Config for the reference */
    config: z.string().default('{}'),
    /** Optional permalink for the reference */
    specPermalink: z.string().default(''),
    /** Whether to publish the reference or not */
    show: z.boolean().default(true),
    /** Option to show the header link as a button */
    isButton: z.boolean().default(false),
    /** Option to force the header link to the top nav */
    isStarred: z.boolean().default(false),
});
function defaultReference() {
    return referenceSchema.parse({
        uid: nanoid(),
        title: '',
        description: '',
        yjsReference: nanoid(),
    });
}

export { defaultReference, referenceSchema };
