import { createGuideChildrenRoutes } from '@/router/guide'
import type { PublishReferenceProps } from '@/views/references'
import {
  type Project,
  type Version,
  getHeaderLinks,
  getFirst,
} from '@scalar-org/entities/project'
import { normalizePath, uidUniqueSlugs } from '@scalar-org/helpers/url'
import type { RouteRecordRaw } from 'vue-router'
import {
  findFirstPage,
  getFallbackTemplates,
  validateDocsPathTemplate,
} from '@scalar-org/helpers/router'
import { createGuideErrorRoutes } from '@/router/errors'
declare module 'vue-router' {
  /**
   * Extends RouteMeta with our types.
   * @see https://router.vuejs.org/guide/advanced/meta.html#TypeScript
   */
  interface RouteMeta {
    /**
     * A render key so Vue knows when to render vs patch the router view.
     * @see https://vuejs.org/api/built-in-special-attributes#key
     */
    key?: string
  }
}

export function generateRoutes(
  project: Project,
  projectVersions: Record<string, Version>,
  isPaid: boolean,
) {
  const versions = Object.values(projectVersions)
  const fallbacks = getFallbackTemplates(versions.length)
  /**
   * These path templates will be used to generate the routes paths
   * They will be normalized and split into an array of keys
   * The keys will then be checked to ensure they are in the correct order
   */
  const { path: guideTemplate, issues: guideIssues } = validateDocsPathTemplate(
    {
      base: project.website.routing.guidePathPattern || fallbacks.guide,
      type: versions.every((v) => Object.keys(v.guides).length <= 1)
        ? 'flat-guide'
        : 'guide',
      multipleVersions: versions.length > 1,
      multipleLocales: false,
    },
  )

  const { path: referenceTemplate, issues: referenceIssues } =
    validateDocsPathTemplate({
      base: project.website.routing.referencePathPattern || fallbacks.reference,
      type: 'reference',
      multipleVersions: versions.length > 1,
      multipleLocales: false,
    })

  if (guideIssues.length > 0 || referenceIssues.length > 0) {
    throw Error(
      'Invalid path templates provided. Please check your project settings.',
      {
        cause: {
          guideIssues,
          referenceIssues,
          fallbacks,
          website: project.website,
        },
      },
    )
  }

  const primaryVersion = versions.find((v) => v.uid === project.activeVersionId)
  if (!primaryVersion) throw Error('Missing primary published version')

  /** List of version names for the version option select */
  const versionNames: Record<string, string> = {}
  versions.forEach((v) => {
    versionNames[v.uid] = v.name
  })

  /**
   * Main route list.
   * The root path will always redirect to the primary version
   * The primary version can then redirect to the first guide or reference as needed
   */
  const routes: RouteRecordRaw[] = [
    {
      path: '/',
      name: 'main',
      redirect: { name: primaryVersion.uid },
    },
  ]

  // Add all explicit redirects
  project.website.redirects.forEach((r) => {
    routes.push(
      {
        path: encodeURI(r.from),
        redirect: r.to,
      },
      {
        path: r.from,
        redirect: r.to,
      },
    )
  })

  // Unique slugs for each version keyed by uid
  const versionSlugs = uidUniqueSlugs(versions, 'name')

  // --------------------------------------------------------------------------
  // Generate the subroutes for each version
  versions.forEach((v) => {
    const versionSlug = versionSlugs[v.uid]
    if (!versionSlug) throw Error('Missing valid pathname for version')

    const versionRoute: RouteRecordRaw = {
      path: normalizePath(
        guideTemplate
          // If version is specified we substitute the slug
          .replace('{{version}}', versionSlug)
          // Guide must be removed as it sites at a lower level
          .replace('{{guide}}', ''),
        { addLeadingSlash: true },
      ),
      name: v.uid,
      // We redirect the root to either the first guide OR the first reference if no guides exists
      redirect: {
        name: getFirst('guide', v)?.uid || getFirst('reference', v)?.uid,
      },
    }

    routes.push(versionRoute)

    const guideSlugs = uidUniqueSlugs(Object.values(v.guides), 'title')
    const referenceSlugs = uidUniqueSlugs(Object.values(v.references), 'title')
    const componentProps: Pick<
      PublishReferenceProps,
      'project' | 'version' | 'headerLinks' | 'versionNames' | 'isPaid'
    > = {
      project,
      version: v,
      headerLinks: getHeaderLinks(v),
      versionNames,
      isPaid,
    }

    // For each guide push we get a set of children page routes and then add the guide route to the version
    Object.values(v.guides).forEach((g) => {
      const guideSlug = guideSlugs[g.uid]
      if (guideSlug === undefined) return

      const firstPageIdx = findFirstPage(g.sidebar.items, g.sidebar.children)

      const path = normalizePath(
        guideTemplate
          .replace('{{version}}', versionSlug)
          .replace('{{guide}}', guideSlug),
        { addLeadingSlash: true },
      )

      const guideRoute: RouteRecordRaw = {
        path,
        name: g.uid,
        redirect: { name: firstPageIdx },
        children: createGuideChildrenRoutes(g, componentProps),
        alias: encodeURI(path),
      }

      routes.push(guideRoute)
      routes.push(
        ...createGuideErrorRoutes({
          parentSlug: path,
          project,
          primaryVersion,
          versionNames,
          guide: g,
        }),
      )
    })

    // For each reference we push the reference page to the version routes
    Object.values(v.references).forEach((r) => {
      const refSlug = referenceSlugs[r.uid]
      if (!refSlug) return

      const props: PublishReferenceProps = {
        ...componentProps,
        referenceUid: r.uid,
        specPermalink: r.specPermalink || '',
      }
      const path = normalizePath(
        referenceTemplate
          .replace('{{version}}', versionSlug)
          .replace('{{reference}}', refSlug),
        { addLeadingSlash: true },
      )
      const referenceRoute: RouteRecordRaw = {
        path,
        alias: encodeURI(path),
        name: r.uid,
        component: () =>
          import(`@/views/references/codegen/_ReferenceProvider-${r.uid}.vue`),
        props,
      }

      routes.push(referenceRoute)
    })
  })

  routes.push(
    ...createGuideErrorRoutes({
      parentSlug: '',
      project,
      primaryVersion,
      versionNames,
      guide: Object.values(primaryVersion.guides)[0],
    }),
  )
  return routes
}
