import { timestampSchema } from '../utility/index.js';
import { z } from 'zod';

const activeDeploymentSchema = z.object({
    /** uid of the publish record for the last successful deployment */
    uid: z.string(),
    /** subdomain or custom domain */
    domain: z.string(),
    /** timestamp of the last successful deployment */
    publishedAt: timestampSchema,
    /** true if the deployment is private */
    isPrivate: z.boolean().default(false),
});

export { activeDeploymentSchema };
