import { GuideView, type PublishGuideProps } from '@/views/guides'
import {
  type Folder,
  type Guide,
  type Page,
  SidebarType,
} from '@scalar-org/entities/project'
import type { RouteRecordRaw } from 'vue-router'

import { findFirstPage, recursivePathMap } from '@scalar-org/helpers/router'

/** Get all the route components for the guides of a given version */
export function createGuideChildrenRoutes(
  guide: Guide,
  /** Component props used for all router pages */
  componentProps: Pick<
    PublishGuideProps,
    'project' | 'version' | 'versionNames' | 'headerLinks' | 'isPaid'
  >,
) {
  const guideRoutes: RouteRecordRaw[] = []

  // Need to strip out link sidebar items for the URL map
  const routeItems: Record<string, Folder | Page> = {}
  Object.entries(guide.sidebar.items).forEach(([uid, item]) => {
    if (item?.type !== SidebarType.Link && item) routeItems[uid] = item
  })

  /** Gets the slugified paths for each guide page. Some pages may have multiple nesting levels */
  const pathMap = recursivePathMap(routeItems, guide.sidebar.children, '')

  Object.values(routeItems).forEach((item) => {
    // For folders we only add a route if there is a valid child. If so we redirect to that first child
    if (item.type === SidebarType.Folder) {
      const firstFolderPage = findFirstPage(routeItems, item.children)
      if (firstFolderPage) {
        guideRoutes.push({
          path: pathMap[item.uid],
          alias: encodeURI(pathMap[item.uid]),
          name: item.uid,
          redirect: { name: firstFolderPage },
        })
      }
    }

    // const staticData = staticDocs[item.uid]

    // We only publish pages with data
    if (item.type !== SidebarType.Page) return

    const props: PublishGuideProps = {
      page: item,
      guide: guide,
      dataFilename: item.uid,
      ...componentProps,
    }

    const sidebarRoute: RouteRecordRaw = {
      path: pathMap[item.uid],
      alias: encodeURI(pathMap[item.uid]),
      // Page UID is used as the name to allow direct navigation from any component
      name: item.uid,
      component: GuideView,
      props,
    }

    guideRoutes.push(sidebarRoute)
  })

  // hotfix to filter out null paths
  return guideRoutes.filter((r) => r.path)
}
