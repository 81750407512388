import { nanoid } from 'nanoid';
import { z } from 'zod';
import { nanoidSchema } from '../../utility/index.js';
import { sidebarSchema, defaultPage } from './sidebar.js';

const guideSchema = z.object({
    /** Unique identifier for the guide */
    uid: nanoidSchema,
    /** Title of the guide */
    title: z.string().transform((val) => (val === '' ? 'Guide' : val)),
    /** Description of the guide */
    description: z.string(),
    /** Sidebar configuration for the guide */
    sidebar: sidebarSchema,
    /** Whether to publish the guide or not */
    show: z.boolean().default(true),
    /** Option to show the header link as a button */
    isButton: z.boolean().default(false),
    /** Option to force the header link to the top nav */
    isStarred: z.boolean().default(false),
});
function defaultGuide() {
    const firstPage = defaultPage();
    return guideSchema.parse({
        uid: nanoid(),
        title: 'Guide',
        description: '',
        sidebar: {
            items: {
                [firstPage.uid]: firstPage,
            },
            children: [firstPage.uid],
        },
        show: true,
        isButton: false,
        isStarred: false,
    });
}

export { defaultGuide, guideSchema };
