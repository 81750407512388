import { defineComponent as S, ref as y, watchEffect as E, computed as B, watch as C, createElementBlock as l, openBlock as n, normalizeStyle as P, normalizeClass as u, unref as t, createCommentVNode as s, createElementVNode as c, withDirectives as N, renderSlot as d, createStaticVNode as V, createVNode as w, vShow as D } from "vue";
import { useResizeObserver as z, useMediaQuery as $ } from "@vueuse/core";
import { useEditorStore as G } from "../editor/store.js";
import { EditorClasses as L } from "../styles.js";
import M from "./PageNav.vue.js";
import "./PageNav.vue2.js";
import O from "./TableOfContents.vue.js";
import { editorId as T } from "../constants/index.js";
import { DocumentClasses as g } from "@scalar-org/library/themes";
const R = ["id"], H = {
  key: 0,
  class: "layout-header"
}, Q = {
  key: 1,
  class: "layout-aside-content"
}, _ = ["id"], j = { class: "layout-aside-right" }, q = { class: "layout-footer" }, x = /* @__PURE__ */ S({
  __name: "Layout",
  props: {
    activePage: {},
    editorViewportId: {},
    showSidebar: { type: Boolean },
    footerBelowSidebar: { type: Boolean },
    sidebar: {},
    headings: {},
    isLoaded: { type: Boolean }
  },
  emits: ["goToNextPage", "goToPrevPage"],
  setup(k) {
    const o = k, { setItem: v, state: f } = G(), p = y(0), b = y(null);
    z(b, (e) => {
      p.value = e[0].contentRect.height;
    });
    const h = $("(max-width: 1120px)"), m = $("(max-width: 1000px)");
    E(() => {
      var e, i, r, a;
      v("pageTitle", ((e = o.activePage) == null ? void 0 : e.title) || ""), v("pageDescription", ((i = o.activePage) == null ? void 0 : i.description) || ""), v("pageImage", ((r = o.activePage) == null ? void 0 : r.backgroundImage) || ""), v("sidebarItems", ((a = o.sidebar) == null ? void 0 : a.items) || {});
    });
    const I = B(() => {
      var e;
      return `Guide / ${(e = o.activePage) == null ? void 0 : e.title}`;
    });
    return C(
      () => {
        var e;
        return (e = o.activePage) == null ? void 0 : e.uid;
      },
      () => {
        const e = document.getElementById(T);
        e && (e.scrollTop = 0);
      }
    ), (e, i) => {
      var r;
      return n(), l("main", {
        id: t(T),
        ref_key: "documentEl",
        ref: b,
        class: u({
          [t(g).Document]: !0,
          "footer-below-sidebar": e.footerBelowSidebar,
          "hide-aside-right": t(h)
        }),
        style: P({ "--full-height": `${p.value}px` })
      }, [
        t(m) ? s("", !0) : (n(), l("div", H, [
          d(e.$slots, "header", {}, void 0, !0)
        ])),
        c("aside", {
          class: u(["layout-aside-left", t(g).Sidebar])
        }, [
          t(m) ? d(e.$slots, "mobile-header", {
            key: 0,
            label: I.value
          }, void 0, !0) : s("", !0),
          e.showSidebar || !t(m) ? (n(), l("div", Q, [
            t(m) ? d(e.$slots, "header", { key: 0 }, void 0, !0) : s("", !0),
            d(e.$slots, "guide-sidebar", {}, void 0, !0)
          ])) : s("", !0)
        ], 2),
        t(f).pageImage ? (n(), l("div", {
          key: 1,
          class: "layout-image",
          style: P(`background-image: url(${t(f).pageImage})`)
        }, null, 4)) : s("", !0),
        N(c("div", {
          class: u(["editor layout-content", t(L).Editor])
        }, [
          i[2] || (i[2] = V('<div class="section-flare-guide" data-v-9310778b><div class="section-flare" data-v-9310778b><div class="section-flare-item" data-v-9310778b></div><div class="section-flare-item" data-v-9310778b></div><div class="section-flare-item" data-v-9310778b></div><div class="section-flare-item" data-v-9310778b></div><div class="section-flare-item" data-v-9310778b></div><div class="section-flare-item" data-v-9310778b></div><div class="section-flare-item" data-v-9310778b></div><div class="section-flare-item" data-v-9310778b></div></div></div>', 1)),
          d(e.$slots, "editor-content", {}, void 0, !0),
          w(M, {
            activePage: e.activePage,
            sidebar: e.sidebar,
            onGoToNextPage: i[0] || (i[0] = (a) => e.$emit("goToNextPage", a)),
            onGoToPrevPage: i[1] || (i[1] = (a) => e.$emit("goToPrevPage", a))
          }, null, 8, ["activePage", "sidebar"]),
          c("div", {
            id: e.editorViewportId,
            class: "layout-content-viewport"
          }, null, 8, _)
        ], 2), [
          [D, e.isLoaded]
        ]),
        c("aside", j, [
          t(h) ? s("", !0) : (n(), l("div", {
            key: 0,
            class: u(["custom-scroll custom-scroll-self-contain-overflow", [t(g).TableOfContents]])
          }, [
            w(O, {
              headings: e.headings,
              pageTitle: ((r = e.activePage) == null ? void 0 : r.title) || ""
            }, null, 8, ["headings", "pageTitle"])
          ], 2))
        ]),
        c("div", q, [
          d(e.$slots, "footer", {}, void 0, !0)
        ])
      ], 14, R);
    };
  }
});
export {
  x as default
};
