import { z } from 'zod';
import { nanoidSchema } from '../utility/index.js';
import { nanoid } from 'nanoid';

const headerLinkBaseSchema = z.object({
    uid: nanoidSchema,
    title: z.string(),
    show: z.boolean().default(true),
    isStarred: z.boolean().default(false),
    type: z
        .union([
        z.literal('guide'),
        z.literal('reference'),
        z.literal('link'),
        z.literal('group'),
        z.literal('version-selector'),
        z.literal('spacer'),
    ])
        .default('link'),
});
const headerLinkReferenceSchema = headerLinkBaseSchema.extend({
    type: z.literal('reference'),
    /** Whether the reference has a permalinked reference or not */
    hasPermalink: z
        .boolean()
        .default(false)
        .describe('Whether the reference has a permalinked reference or not'),
});
const headerLinkGuideSchema = headerLinkBaseSchema.extend({
    type: z.literal('guide'),
});
const headerLinkVersionSelectorSchema = headerLinkBaseSchema.extend({
    type: z.literal('version-selector'),
});
const headerLinkSpacerSchema = headerLinkBaseSchema.extend({
    type: z.literal('spacer'),
});
const headerLinkExternalSchema = headerLinkBaseSchema.extend({
    type: z.literal('link'),
    isButton: z.boolean().default(false),
    link: z.string(),
});
const headerLinkGroupSchema = headerLinkBaseSchema.extend({
    type: z.literal('group'),
    /** Children of the group. Groups cannot contain other groups, spacers, or the version selector */
    children: z
        .array(z.union([
        headerLinkGuideSchema,
        headerLinkReferenceSchema,
        headerLinkExternalSchema,
    ]))
        .describe('Children of the group. Groups cannot contain other groups, spacers, or the version selector'),
});
const headerLinkSchema = z.union([
    headerLinkGuideSchema,
    headerLinkReferenceSchema,
    headerLinkGroupSchema,
    headerLinkExternalSchema,
    headerLinkVersionSelectorSchema,
    headerLinkSpacerSchema,
]);
/**
 * Create the complete list of header links for the guides and references
 * This will be run as part of the version schema egress to ensure that
 * guide and reference properties are always propagated to the corresponding header link
 *
 * This eliminates the need to update the field on the header link itself
 */
function getHeaderLinks({ guides, references, header, }) {
    // The version handles the determined order of the header links
    // We filter out any links that are not present in the guides or references
    // During publish we may remove guides or references without content
    const complete = [...header].filter((h) => {
        if (h.type === 'guide')
            return !!guides[h.uid];
        if (h.type === 'reference')
            return !!references[h.uid];
        return true;
    });
    const orderedIds = [];
    complete.forEach((h) => {
        orderedIds.push(h.uid);
        if (h.type === 'group') {
            orderedIds.push(...h.children.map((c) => c.uid));
        }
    });
    // Add any missing links from the guides
    Object.values(guides).forEach((g) => {
        if (!orderedIds.includes(g.uid)) {
            complete.push({
                uid: g.uid,
                title: g.title,
                show: g.show,
                isStarred: g.isStarred,
                type: 'guide',
            });
        }
    });
    // Add any missing links from the references
    Object.values(references).forEach((r) => {
        if (!orderedIds.includes(r.uid)) {
            complete.push({
                uid: r.uid,
                title: r.title,
                show: r.show,
                isStarred: r.isStarred,
                type: 'reference',
                hasPermalink: !!r.specPermalink,
            });
        }
    });
    // Add the version selector if it is not already present
    if (!complete.find((e) => e.type === 'version-selector')) {
        complete.push({
            uid: nanoid(),
            type: 'spacer',
            title: '',
            show: false,
            isStarred: false,
        }, {
            uid: nanoid(),
            title: 'Version Selector',
            type: 'version-selector',
            show: true,
            isStarred: false,
        });
    }
    // We will store the primary properties in the guide and reference
    // and then use them as the source of truth for the header links
    complete.forEach((element) => {
        if (element.type === 'guide') {
            element.title = guides[element.uid].title;
            element.show = guides[element.uid].show;
            element.isStarred = guides[element.uid].isStarred;
        }
        if (element.type === 'reference') {
            element.title = references[element.uid].title;
            element.show = references[element.uid].show;
            element.isStarred = references[element.uid].isStarred;
            element.hasPermalink = !!references[element.uid].specPermalink;
        }
        if (element.type === 'group') {
            element.children.forEach((c) => {
                if (c.type === 'guide') {
                    c.title = guides[c.uid].title;
                    c.show = guides[c.uid].show;
                    c.isStarred = guides[c.uid].isStarred;
                }
                if (c.type === 'reference') {
                    c.title = references[c.uid].title;
                    c.show = references[c.uid].show;
                    c.isStarred = references[c.uid].isStarred;
                    c.hasPermalink = !!references[c.uid].specPermalink;
                }
            });
        }
    });
    // Add any missing links from the guides
    return complete;
}

export { getHeaderLinks, headerLinkSchema };
