import { projectIconSchema } from '../icon.js';
import { nanoidSchema } from '../../utility/index.js';
import { nanoid } from 'nanoid';
import { z } from 'zod';

var SidebarType;
(function (SidebarType) {
    SidebarType["Page"] = "Page";
    SidebarType["Folder"] = "Folder";
    SidebarType["Link"] = "Link";
})(SidebarType || (SidebarType = {}));
const baseSidebarItemSchema = z.object({
    uid: nanoidSchema,
    title: z.string().trim().min(1),
    type: z.nativeEnum(SidebarType),
    link: z.string().optional(),
    icon: projectIconSchema.optional(),
    children: z.string().array().optional(),
    description: z.string().optional(),
    show: z.boolean(),
});
// ---------------------------------------------------------------------------
const linkSchema = baseSidebarItemSchema.extend({
    title: z.preprocess((arg) => arg?.trim() || 'Link', z.string().trim().min(1)),
    link: z.string(),
    type: z.literal(SidebarType.Link),
});
// ---------------------------------------------------------------------------
const pageSchema = baseSidebarItemSchema.extend({
    title: z.preprocess((arg) => arg?.trim() || 'Page', z.string().trim().min(1)),
    description: z.string(),
    children: z.string().array().default([]),
    type: z.literal(SidebarType.Page),
    yjsReference: z.string(),
    backgroundImage: z.string().optional(),
    icon: projectIconSchema.optional(),
});
const defaultPage = () => ({
    uid: nanoid(),
    title: 'Getting Started',
    type: SidebarType.Page,
    description: 'Start writing your API Documentation with our platform',
    children: [],
    show: true,
    yjsReference: nanoid(),
});
// ---------------------------------------------------------------------------
const folderSchema = baseSidebarItemSchema.extend({
    title: z.preprocess((arg) => arg?.trim() || 'Folder', z.string().trim().min(1)),
    children: z.string().array().default([]),
    type: z.literal(SidebarType.Folder),
});
// ---------------------------------------------------------------------------
const sidebarItemSchema = z.union([
    linkSchema,
    pageSchema,
    folderSchema,
]);
// ---------------------------------------------------------------------------
const sidebarSchema = z
    .object({
    /** Complete record of ALL sidebar items */
    items: z.record(z.string(), sidebarItemSchema),
    /** Ordered list of the direct children */
    children: z.string().array(),
})
    .transform((val) => {
    // Ensure at least 1 page exists for a guide
    const hasPage = Object.values(val.items).some((item) => item.type === SidebarType.Page);
    if (!hasPage) {
        const firstPage = defaultPage();
        val.items[firstPage.uid] = firstPage;
        val.children.push(firstPage.uid);
    }
    return val;
});

export { SidebarType, defaultPage, sidebarItemSchema, sidebarSchema };
