import { defineComponent as d, createBlock as s, openBlock as n, createSlots as l, withCtx as r, createElementVNode as p, renderList as g, renderSlot as P, normalizeProps as m, guardReactiveProps as w } from "vue";
import b from "./Layout.vue.js";
const S = ["innerHTML"], B = /* @__PURE__ */ d({
  __name: "ApiGuideStatic",
  props: {
    activePage: {},
    editorViewportId: {},
    showSidebar: { type: Boolean },
    footerBelowSidebar: { type: Boolean },
    sidebar: {},
    headings: {},
    htmlContent: {},
    isLoaded: { type: Boolean },
    isPaid: { type: Boolean }
  },
  emits: ["goToNextPage", "goToPrevPage"],
  setup(f) {
    return (e, o) => (n(), s(b, {
      activePage: e.activePage,
      editorViewportId: e.editorViewportId,
      footerBelowSidebar: e.footerBelowSidebar,
      headings: e.headings,
      isLoaded: e.isLoaded,
      isPaid: e.isPaid,
      showSidebar: e.showSidebar,
      sidebar: e.sidebar,
      staticToc: !0,
      onGoToNextPage: o[0] || (o[0] = (i) => e.$emit("goToNextPage", i)),
      onGoToPrevPage: o[1] || (o[1] = (i) => e.$emit("goToPrevPage", i))
    }, l({
      "editor-content": r(() => [
        p("div", {
          class: "active editor-content editor-static",
          innerHTML: e.htmlContent
        }, null, 8, S)
      ]),
      _: 2
    }, [
      g(e.$slots, (i, t) => ({
        name: t,
        fn: r((a) => [
          P(e.$slots, t, m(w(a || {})))
        ])
      }))
    ]), 1032, ["activePage", "editorViewportId", "footerBelowSidebar", "headings", "isLoaded", "isPaid", "showSidebar", "sidebar"]));
  }
});
export {
  B as default
};
