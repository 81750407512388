import { z } from 'zod';

function schemaModel(data, schema, throwError = true) {
    const result = schema.safeParse(data);
    if (!result.success) {
        console.error('Zod Schema Error');
        console.group();
        result.error.issues.forEach((issue) => {
            console.log(`Path: ${issue.path.join(', ')} \nError: ${issue.message}`);
        });
        console.groupEnd();
    }
    if (throwError && !result.success)
        throw new Error('Zod validation failure');
    return result.success ? result.data : null;
}
const emailSchema = z
    .string()
    .email()
    .transform((a) => a.toLowerCase());
const timestampSchema = z.number().int().min(0);
const nanoidSchema = z.string().min(5);
/** Unix timestamp in seconds. Default to current time */
function unixTimestamp(
/** Javascript date or timestamp in milliseconds */
date = Date.now()) {
    return Math.floor(new Date(date).getTime() / 1000);
}
var Environments;
(function (Environments) {
    Environments["Dev"] = "development";
    Environments["Test"] = "test";
    Environments["Prod"] = "production";
    Environments["Staging"] = "staging";
})(Environments || (Environments = {}));
const deployEnvironmentsSchema = z
    .nativeEnum(Environments)
    .optional()
    .default(Environments.Dev);
/**
 * This RegEx is valid as of 2024-11-06.
 * If it reports false positives or false negatives, please check the website for an update!
 * See here: https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
 *
 * Uses numbered capture groups for better javascript support
 */
const regexSemverNumberedGroups = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;
const semverSchema = z.string().regex(regexSemverNumberedGroups);
/** Convert an array of objects to an object with their uid as the key */
function arrayToMap(array) {
    return array.reduce((acc, item) => {
        acc[item.uid] = item;
        return acc;
    }, {});
}
/**
 * RegEx for slug validation
 *
 * Valid Examples:
 * monkey-climbs-trees, kangaroo--jumps---around
 *
 * Invalid Examples:
 * 1test-example.com, -test, example-
 */
const regexSlug = /^[a-z](?:[a-z0-9-]*[a-z0-9])?$/;
/**
 * Zod Schema for slug validation, with a reasonable max length.
 */
const slugSchema = z.string().min(3).max(60).regex(regexSlug, {
    message: 'Slug can have lowercase letters, digits, or hyphens. It must start with a lowercase letter and end with a letter or number.',
});

export { Environments, arrayToMap, deployEnvironmentsSchema, emailSchema, nanoidSchema, regexSemverNumberedGroups, regexSlug, schemaModel, semverSchema, slugSchema, timestampSchema, unixTimestamp };
