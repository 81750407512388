import { generateRoutes } from '@/router'
import { sparsePublishDataSchema } from '@scalar-org/entities/publish'
import { schemaModel } from '@scalar-org/entities/utility'

import { ViteSSG } from 'vite-ssg'

import '@/styles'

import App from './App.vue'
// ---------------------------------------------------------------------------

declare const __SPARSE_DATA__: string

// Vite shenanigans to enable dynamic file naming for the data.json file
// Dynamic filenames allow us to provide a unique data.json name for each build
// eliminating any possibility of cross-team data contamination

const dataFilepath = __SPARSE_DATA__
const jsonModules = import.meta.glob(__SPARSE_DATA__, {
  query: '?raw',
  import: 'default',
})

if (!Object.values(jsonModules).length)
  throw new Error(`NO DATA FILES FOUND FOR ${dataFilepath}`)

const dataFile = await Object.values(jsonModules)[0]()
if (typeof dataFile !== 'string')
  throw new Error(`INVALID DATA FILE IMPORT FOR ${dataFilepath}`)

console.info(`[PUBLISH SITE]: ${dataFilepath}`)
// ---------------------------------------------------------------------------

/** Validate data file that is expected to live in the filesystem */
const data = schemaModel(JSON.parse(dataFile), sparsePublishDataSchema)
const routes = generateRoutes(data.project, data.project.versions, data.isPaid)

// Handle pre-defined themes and fallback
const options: Parameters<typeof ViteSSG>[1] = {
  routes,
}

// Only set the base if the subpath is defined
// TODO: This needs more testing for nested direct paths
// It looks like only root paths work properly and nested requests also nest the asset requests
if (data.project.website.subPath) {
  console.info(`[SUBPATH SITE]: ${data.project.website.subPath}`)
  options.base = data.project.website.subPath
}

export const createApp = ViteSSG(App, options, async ({ app }) => {
  app.provide('theme', data.theme.theme)
  app.provide('project', data.project)
  app.provide('proxyUrl', data.proxy)
  app.provide('typesenseKey', data.typesenseKey)
  app.provide('typesenseHost', data.typesenseHost)
  app.provide('projectUid', data.project.uid)
})

declare global {
  interface Window {
    __SSG_INITIAL_STATE__: Record<string, unknown>
  }
}
