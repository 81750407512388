import { z } from 'zod';
import { themeSchema } from '../theme/index.js';
import { nanoidSchema } from '../utility/index.js';
import { projectSchema } from '../project/project.js';

const headingRecordSchema = z.object({
    slug: z.string(),
    name: z.string(),
    level: z.coerce.number().int(),
    uid: nanoidSchema,
    parent: z.string().optional(),
});
// ---------------------------------------------------------------------------
/** Request from core to publish-deploy for a publish */
const publishDeployRequestSchema = z.object({
    token: z.string(),
    teamUid: nanoidSchema,
    publishUid: nanoidSchema,
    subdomain: z.string(),
    /** A map of page ids to YJS document ids */
    yjsDocs: z.record(z.string(), z.string()),
    /** A map of reference ids to YJS document ids */
    yjsReferences: z.record(z.string(), z.string()),
    project: projectSchema,
    theme: themeSchema,
    customDomain: z.string().nullable(),
    isPaid: z.boolean().optional(),
});
// ---------------------------------------------------------------------------
/** Request from core to publish-deploy for github sync publish */
const publishGithubRequestSchema = z.object({
    token: z.string(),
    teamUid: nanoidSchema,
    publishUid: nanoidSchema,
    subdomain: z.string(),
    customDomain: z.string().optional().default(''),
    documents: z.record(z.string(), z.string()),
    references: z.record(z.string(), z.string()),
    project: projectSchema,
    theme: themeSchema,
    isPaid: z.boolean().default(false),
    gitProjectUid: nanoidSchema,
});
// ---------------------------------------------------------------------------
// JSON format for the statically generated doc files
// ---------------------------------------------------------------------------
const staticDocSchema = z.object({
    html: z.string(),
    headings: z.record(z.string(), headingRecordSchema),
    markdown: z.string(),
});
/**
 * For each page in the static docs we expect a JSON file with this content
 * to be provided in the `assets` directory
 */
const staticContentSchema = z.record(z.string(), staticDocSchema);
/** Data consumed but ViteSSG in the static-docs build */
const sparsePublishDataSchema = z.object({
    project: projectSchema,
    theme: themeSchema,
    isPaid: z.boolean().default(false),
    typesenseKey: z.string(),
    typesenseHost: z.string(),
    proxy: z.string().url(),
});
/** Output data from static-docs provided back publish-deploy for typesense */
const splitOutputDataSchema = z.object({
    staticDocs: staticContentSchema,
    errors: z.string().array(),
});

export { headingRecordSchema, publishDeployRequestSchema, publishGithubRequestSchema, sparsePublishDataSchema, splitOutputDataSchema, staticContentSchema, staticDocSchema };
