import { z } from 'zod';
import { timestampSchema } from '../utility/index.js';

const projectIconDefinitionSchema = z.object({
    /** Key to local icon OR URL to hosted icon */
    src: z.string().default(''),
    /** Image alt or svg title */
    title: z.string().optional(),
    /** Mask a non-svg image */
    mask: z.boolean().optional(),
    /** Search and group tags */
    tags: z.string().array().default([]),
    /** Sorting group in list */
    group: z.enum(['solid', 'line', 'brand', 'custom']),
});
// ---------------------------------------------------------------------------
const customProjectIconSchema = projectIconDefinitionSchema.extend({
    /** Timestamp for when the icon was uploaded */
    createdAt: timestampSchema,
    /** User ID who uploaded the icon */
    uploadedBy: z.string(),
    /** Always a custom icon */
    group: z.literal('custom'),
});
const projectIconInfoSchema = z.object({
    src: z.string().default(''),
    title: z.string().optional(),
    mask: z.boolean().optional(),
});
const projectIconSchema = z.preprocess((arg) => {
    if (!arg)
        return arg;
    if (!arg.src)
        arg.src = arg.local || arg.remote;
    if (arg.local)
        arg.group = 'solid';
    if (arg.remote)
        arg.group = 'custom';
    return arg;
}, projectIconInfoSchema);

export { customProjectIconSchema, projectIconSchema };
