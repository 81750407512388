<script setup lang="ts">
import { type HeadingRecord } from '@scalar-org/entities/publish'
import {
  ApiGuideStatic,
  customFooterClass,
  type GuideStaticProps,
} from '@scalar-org/guide'
import {
  DocumentHeader,
  DocumentMobileHeader,
} from '@scalar-org/library/composed'
import { useColorMode } from '@scalar/use-hooks/useColorMode'
import { useHead, useSeoMeta } from '@unhead/vue'
import {
  computed,
  nextTick,
  onServerPrefetch,
  onUpdated,
  ref,
  watch,
} from 'vue'
import { useRouter } from 'vue-router'

import { resolveHeaderLink } from '@/helpers/resolveLink'
import { useSidebarState } from '@/hooks'
import { runHydration } from '@/hydration'

import PublishSidebarContent from './GuideSidebarContent.vue'
import { type PublishGuideProps } from './types'

const props = defineProps<PublishGuideProps>()

useSeoMeta({
  title: () => `${props.page.title} | ${props.guide.title}`,
  // TODO: Consider if we want to override the description
  // description: props.page.description ?? props.guide.description,
})
useHead({
  script: [
    {
      tagPosition: 'bodyClose',
      textContent: () => props.version.bodyScript,
    },
    {
      tagPosition: 'head',
      textContent: () => props.version.headScript,
    },
  ],
  style: [
    {
      tagPosition: 'head',
      textContent: () => props.version.footerCss,
    },
  ],
})

const { sidebarState, toggleSidebar, setSidebar, setSearch } = useSidebarState()

const html = ref<string>('')
const headings = ref<Record<string, HeadingRecord>>({})

const router = useRouter()

async function loadContent() {
  if (!props.dataFilename) return
  try {
    const content = await import(`@/assets/${props.dataFilename}.json`)
    html.value = content.default.html
    headings.value = content.default.headings
  } catch {
    console.error('Error loading static data', props.dataFilename)
  }
}

// Preload the content during static generation
onServerPrefetch(async () => {
  await loadContent()
})

// Components are not always unmounted on router changes so we need to watch the dataFilename
watch(
  () => props.dataFilename,
  async () => loadContent(),
  { immediate: true },
)

const guideProps = computed(
  (): GuideStaticProps => ({
    showSidebar: sidebarState.showSidebar,
    activePage: props.page,
    sidebar: props.guide.sidebar,
    footerBelowSidebar: props.version.footerBelowSidebar,
    headings: headings.value,
    htmlContent: html.value,
    isLoaded: true,
    isPaid: props.isPaid,
  }),
)

const { isDarkMode } = useColorMode()

/**
 * Run hydration after the component has updated
 * Watching the page UID proved to be unreliable
 * With onUpdated we must ensure we can handle multiple calls with the same content
 */
onUpdated(async () => {
  if (import.meta.env.SSR) return
  await nextTick()
  runHydration(router, isDarkMode)
})

function navigate(uid: string) {
  router.push({ name: uid, replace: true })
}
</script>
<template>
  <ApiGuideStatic
    v-bind="guideProps"
    @goToNextPage="navigate"
    @goToPrevPage="navigate">
    <template #header>
      <DocumentHeader
        :activeGuide="props.guide.uid"
        :activeVersion="props.version.uid"
        :headerLinks="headerLinks"
        :project="project"
        :resolveInternalLinks="(item) => resolveHeaderLink(item, router)"
        :versions="versionNames"
        @navigate="(_, uid) => navigate(uid)"
        @selectVersion="navigate"
        @setSidebar="setSidebar" />
    </template>
    <template #mobile-header="headerProps">
      <DocumentMobileHeader
        :label="headerProps.label"
        :showSideBar="sidebarState.showSidebar"
        @showSearch="() => setSearch(true)"
        @toggleSidebar="toggleSidebar" />
    </template>
    <template #guide-sidebar>
      <PublishSidebarContent
        :activePage="page"
        :isPaid="isPaid"
        :sidebar="guide.sidebar">
      </PublishSidebarContent>
    </template>
    <template
      v-if="version.footer"
      #footer>
      <div
        class="layout-footer"
        :class="customFooterClass"
        v-html="version.footer" />
    </template>
  </ApiGuideStatic>
</template>
<style scoped>
.app-document {
  overflow: auto;
  width: 100dvw;
  height: 100dvh;
}
</style>
