import { SidebarType as h } from "@scalar-org/entities/project";
function p(n, e) {
  const f = n.items, i = [];
  function l(t) {
    var u, d, o, c;
    ((u = f[t]) == null ? void 0 : u.type) === h.Page && i.push(t), (d = f[t]) != null && d.children && ((c = (o = f[t]) == null ? void 0 : o.children) == null || c.forEach((U) => {
      l(U);
    }));
  }
  n.children.forEach((t) => l(t));
  const r = i.indexOf(e);
  return r === -1 ? { prevUid: null, nextUid: null } : {
    prevUid: r > 0 ? i[r - 1] : null,
    nextUid: r < i.length - 1 ? i[r + 1] : null
  };
}
function x(n, e) {
  return !e || !n ? null : p(n, e.uid).prevUid;
}
function m(n, e) {
  return !e || !n ? null : p(n, e.uid).nextUid;
}
export {
  m as findNextPage,
  x as findPreviousPage,
  p as getAdjacentUids
};
