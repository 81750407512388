import type { PublishReferenceProps } from '@/views/references'
import type {
  Guide,
  HeaderLink,
  Project,
  Version,
} from '@scalar-org/entities/project'
import type { RouteRecordRaw } from 'vue-router'

import NotFound from '@/views/404.vue'
import { normalizePath } from '@scalar-org/helpers/url'

/**
 * For a missing guide (or version) we show a 404 page with a guide view
 *
 * This must only exist on the /404 page as we rely on proxy redirects to get here (not vue router)
 */
export const createGuideErrorRoutes = ({
  project,
  primaryVersion,
  versionNames,
  guide,
  parentSlug,
}: {
  parentSlug: string
  project: Project
  primaryVersion: Version
  versionNames: Record<string, string>
  guide?: Guide
}): RouteRecordRaw[] => {
  return [
    {
      path: normalizePath(`/${parentSlug}/404`, { addLeadingSlash: true }),
      name: `NotFound-${parentSlug}`,
      component: NotFound,
      props: {
        project,
        activeVersion: primaryVersion,
        versionNames,
        guide,
      } satisfies InstanceType<typeof NotFound>['$props'],
    },
    {
      path: normalizePath(`/${parentSlug}/:catchAll(.*)`, {
        addLeadingSlash: true,
      }),
      name: `CatchAll-${parentSlug}`,
      redirect: { name: `NotFound-${parentSlug}` },
    },
  ]
}

/**
 * For a missing specification file we show a 404 page with a reference view
 */
export const createReferenceErrorRoutes = ({
  pathPrefix,
  ...props
}: {
  pathPrefix: string
  project: Project
  version: Version
  versionNames: Record<string, string>
  headerLinks: HeaderLink[]
  isPaid: boolean
}) => {
  return [
    {
      path: `${pathPrefix}/404`,
      name: `NotFound-${pathPrefix}`,
      component: () => import('@/views/references/Reference404.vue'),
      props: props satisfies Partial<PublishReferenceProps>,
    },
  ]
}
